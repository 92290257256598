import Glide, { Autoplay, Breakpoints, Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
import { parseInt } from 'lodash'

document.addEventListener('alpine:init', () => {
    Alpine.data('initGlide', (opt = null) => ({
        g: null,
        init: function () {
            // Get options (or create from defaults)
            let options = opt
            if (!options) {
                options = {
                    0: 3,
                    500: 2,
                    768: 3
                }
            }

            // Process options
            // Format for break point options are like {0:4, 640: '3,17'}
            // 0 is default, any other number is another breakpoint.
            // If a number is provided - that is the number of slides per view
            // If a coma separated string is provided (ie 3,17) the first is the number of sliders per view and the second is the gap
            const breakpoints = {}
            Object.keys(options)
                .filter(o => o !== '0')
                .filter(o => parseInt(o))
                .forEach(o => {
                    if (!parseInt(options[o]) && options[o].indexOf(',')) {
                        const expanded = options[o].split(',')
                        breakpoints[o] = {
                            perView: parseInt(expanded[0]),
                            gap: parseInt(expanded[1])
                        }
                    } else {
                        breakpoints[o] = {
                            perView: parseInt(options[o])
                        }
                    }

                })

            const processOptions = {
                perView: options[0],
                breakpoints: breakpoints,
                gap: 24,
                autoplay: options['autoplay'] || false,
                type: options['type'] || 'carousel',
                swipeThreshold: 80
            }

            this.g = new Glide(this.$refs.glide, processOptions)
        },

        show: {
            ['x-intersect.margin.200.once']() {
                let slideCount = this.$el.getElementsByClassName('glide__slide').length
                if (this.g && slideCount > 1) {
                    this.g.mount({ Controls, Breakpoints, Autoplay, Swipe })
                } else {
                    this.$el.getElementsByClassName('glide__bullets')[0].remove()
                    this.$el.getElementsByClassName('glide__arrows')[0].remove()
                }
            }
        }
    }))
})